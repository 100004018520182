import { disabledDateRange } from "../components/app/booking/RoomDetailAvailabilityModal";
import { parseAppSettings } from "../types/bookings";
import { parseRoom, parseRooms } from "../types/room";
import { mutation, qsUrl, query } from "../utils";
import { api } from "./api";
import {
  ApiResponse,
  AppSettingsDto,
  BookingBaseDto,
  BookingCreatedResponse,
  BookingValidationResponse,
  HotelId,
  PaymentMethodType,
  RoomDto,
  RoomId,
  SettingsDto,
} from "./types";

const ROOT_KEY = ["hotel"];

export interface BookingCreateData {
  settings: SettingsDto;
  bookings: BookingBaseDto[];
  contactDetails: { name: string; surname: string; email: string; phone: string };
  invoiceDetails: {
    name: string;
    surname: string;
    street: string;
    city: string;
    zip: string;
    country: string;
    companyName?: string;
    identificationNumber?: string;
    taxNumber?: string;
  };
  paymentMethod: PaymentMethodType;
  message?: string;
}

export interface BookingValidationData {
  bookings: BookingBaseDto[];
  settings: SettingsDto;
  prices: {
    totalPrice?: number;
    totalPriceTax: number;
  };
}

export interface BookingRequestData {
  bookingCode: string;
  requestToken: string;
}

export interface BookingDetailData {
  bookingCode: string;
  checkIn: string;
  checkOut: string;
  contactName: string;
  contactSurname: string;
  totalPrice: string;
}

const ENDPOINTS = {
  GET_ROOM: (roomId: RoomId) => `/bookingSystem/getUnit/${roomId}`,
  GET_ROOMS: () => `/bookingSystem/loadAvailabilities`,
  GET_ROOM_AVAILABILITY: () => `/bookingSystem/checkAvailability`,
  CREATE_BOOKING: () => `/bookingSystem/createBooking`,
  VALIDATE_BOOKING: () => `/bookingSystem/validateBooking`,
  GET_APP_SETTINGS: (hotelId: HotelId) => `/bookingSystem/getAppSettings/${hotelId}`,
  GET_BLOCKED_SCOPES: () => `/bookingSystem/blockedSlots`,
  BOOKING: (bookingCode: string) => `/bookingSystem/booking/${bookingCode}`,
};

export const useBookingApi = () => {
  const getAppSettings = query(
    async (hotelId: HotelId) =>
      parseAppSettings((await api.get<ApiResponse<AppSettingsDto>>(ENDPOINTS.GET_APP_SETTINGS(hotelId))).data.results),
    [...ROOT_KEY],
  );

  const getRoom = query(
    async (roomId: RoomId) =>
      parseRoom((await api.get<ApiResponse<RoomDto>>(ENDPOINTS.GET_ROOM(roomId))).data.results!),
    [...ROOT_KEY, "room"],
  );

  const getRooms = query(
    async (hotelId: HotelId, startDate: Date, endDate: Date) =>
      parseRooms(
        (
          await api.post<ApiResponse<RoomDto[]>>(ENDPOINTS.GET_ROOMS(), {
            objectId: hotelId,
            startDate,
            endDate,
          })
        ).data.results,
      ),
    [...ROOT_KEY, "rooms"],
  );

  const getBookingDetail = query(
    async ({ requestToken, bookingCode }: BookingRequestData) =>
      (await api.get<ApiResponse<BookingDetailData>>(qsUrl(ENDPOINTS.BOOKING(bookingCode), { requestToken }))).data
        .results!,
    [...ROOT_KEY, "booking"],
  );

  const checkAvailability = query(
    async (hotelId: HotelId, roomId: RoomId, startDate: Date, endDate: Date) =>
      parseRooms(
        (
          await api.post<ApiResponse<any>>(ENDPOINTS.GET_ROOM_AVAILABILITY(), {
            objectId: hotelId,
            unitId: roomId,
            startDate,
            endDate,
          })
        ).data.results,
      ),
    [...ROOT_KEY, "room", "availability"],
  );

  const checkRoomAvailability = () =>
    mutation(
      async ({
        hotelId,
        roomId,
        startDate,
        endDate,
      }: {
        hotelId: HotelId;
        roomId: RoomId;
        startDate: Date;
        endDate?: Date;
      }) =>
        (
          await api.post<ApiResponse<disabledDateRange[]>>(ENDPOINTS.GET_BLOCKED_SCOPES(), {
            objectId: hotelId,
            unitId: roomId,
            startDate,
            endDate,
          })
        ).data.results,
      {},
    );

  const validateCart = query(
    async (payload: BookingValidationData) =>
      (await api.post<ApiResponse<BookingValidationResponse>>(ENDPOINTS.VALIDATE_BOOKING(), payload)).data.results,
    [...ROOT_KEY, "bookings", "validation"],
  );

  const createBooking = () =>
    mutation(
      async (payload: BookingCreateData) =>
        (await api.post<ApiResponse<BookingCreatedResponse>>(ENDPOINTS.CREATE_BOOKING(), payload)).data.results,
      {},
    );

  const cancelBooking = () =>
    mutation(
      async (payload: BookingRequestData) =>
        (
          await api.put<ApiResponse<any>>(
            qsUrl(ENDPOINTS.BOOKING(payload.bookingCode), { requestToken: payload.requestToken }),
          )
        ).data,
      {},
    );

  return {
    getAppSettings,
    getRoom,
    getRooms,
    getBookingDetail,
    checkAvailability,
    checkRoomAvailability,
    createBooking,
    cancelBooking,
    validateCart,
  };
};
