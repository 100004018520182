import {
  Box,
  BoxProps,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputLabel,
  Link,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect } from "react";
import { Control, Controller, useForm, useWatch } from "react-hook-form";
import { Link as RouterLink } from "react-router-dom";

import { getCountryByCode } from "../../../data/countries";
import { PurchaseFormData } from "../../../types/bookings";
import { CountryImage, CountrySelect } from "../../common";

const validateReservationConditions = (keysToCheck: string[]) => (object: Record<string, boolean>) =>
  keysToCheck.every((key) => object[key] === true);

const DEFAULT_REQUIRED_MESSAGE = "Vyplňte toto pole";

const DEFAULT_FORM_DATA: PurchaseFormData = {
  type: "individual",
  companyName: "",
  ico: "",
  dic: "",
  firstName: "",
  lastName: "",
  street: "",
  city: "",
  zip: "",
  email: "",
  country: getCountryByCode("CZ")!,
  phone: "",
  paymentMethod: "online",
  reservationConditions: { agreeToTermsAndPrivacy: false, agreeToHotelMarketing: false },
};

const CHECKBOX_FORM_OFFSET = "-10px";

export interface PurchaseFormControlsProps {
  control: Control<PurchaseFormData>;
  compact?: boolean;
  links: {
    marketingNewsletter: string;
    reservationConditions: string;
  };
}
export const PurchaseFormControls = ({ control, links, compact }: PurchaseFormControlsProps) => {
  const type = useWatch({ control, name: "type" });
  const country = useWatch({ control, name: "country" });

  return (
    <Stack
      display="flex"
      direction={compact ? "column" : "row"}
      spacing={5}
      bgcolor="background.paper"
      borderRadius={2}
      boxShadow={1}
      padding={2}
    >
      <Stack spacing={2} flexGrow={1}>
        <FormLabel>Osobní údaje</FormLabel>
        <Controller
          name="type"
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState: { error } }) => (
            <FormControl error={Boolean(error)}>
              <InputLabel id="type-label">Jste</InputLabel>
              <Select {...field} label="Jste" labelId="type-label">
                <MenuItem value="individual">Fyzická osoba</MenuItem>
                <MenuItem value="company">Společnost</MenuItem>
              </Select>
            </FormControl>
          )}
        />
        {type === "company" && (
          <>
            <Controller
              name="companyName"
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <TextField
                  label="Název společnosti"
                  {...field}
                  error={Boolean(fieldState.error)}
                  helperText={fieldState.error && DEFAULT_REQUIRED_MESSAGE}
                />
              )}
            />
            <Controller
              name="ico"
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <TextField
                  label="IČO"
                  {...field}
                  error={Boolean(fieldState.error)}
                  helperText={fieldState.error && DEFAULT_REQUIRED_MESSAGE}
                />
              )}
            />
            <Controller
              name="dic"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  label="DIČ"
                  {...field}
                  error={Boolean(fieldState.error)}
                  helperText={fieldState.error && DEFAULT_REQUIRED_MESSAGE}
                />
              )}
            />
          </>
        )}
        <Stack spacing={2} direction="row">
          <Controller
            name="firstName"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <TextField
                label="Vaše jméno"
                fullWidth
                {...field}
                error={Boolean(fieldState.error)}
                helperText={fieldState.error && DEFAULT_REQUIRED_MESSAGE}
              />
            )}
          />
          <Controller
            name="lastName"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <TextField
                label="Vaše příjmení"
                fullWidth
                {...field}
                error={Boolean(fieldState.error)}
                helperText={fieldState.error && DEFAULT_REQUIRED_MESSAGE}
              />
            )}
          />
        </Stack>
        <Controller
          name="street"
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <TextField
              label="Ulice a číslo popisné"
              {...field}
              error={Boolean(fieldState.error)}
              helperText={fieldState.error && DEFAULT_REQUIRED_MESSAGE}
            />
          )}
        />
        <Stack spacing={2} direction="row">
          <Controller
            name="city"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <TextField
                fullWidth
                label="Město"
                {...field}
                error={Boolean(fieldState.error)}
                helperText={fieldState.error && DEFAULT_REQUIRED_MESSAGE}
              />
            )}
          />
          <Controller
            name="zip"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <TextField
                label="PSČ"
                fullWidth
                {...field}
                error={Boolean(fieldState.error)}
                helperText={fieldState.error && DEFAULT_REQUIRED_MESSAGE}
              />
            )}
          />
        </Stack>
        <Controller
          name="email"
          control={control}
          rules={{
            required: true,
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: "Zadejte platný e-mail",
            },
          }}
          render={({ field, fieldState }) => (
            <TextField
              label="E-mail"
              {...field}
              error={Boolean(fieldState.error)}
              helperText={
                fieldState.error &&
                (Boolean(fieldState.error.message) ? fieldState.error.message : DEFAULT_REQUIRED_MESSAGE)
              }
            />
          )}
        />
        <Controller
          name="country"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value }, fieldState }) => (
            <CountrySelect
              onChange={(_, nextValue) => onChange?.(nextValue)}
              value={value}
              getOptionLabel={({ label }) => (label ? label : "")}
              isOptionEqualToValue={(option, value) => option.code === value.code}
              labelRender={({ label }) => label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: country && <CountryImage countryCode={country.code} />,
                  }}
                  label="Země"
                  error={Boolean(fieldState.error)}
                  helperText={fieldState.error && DEFAULT_REQUIRED_MESSAGE}
                />
              )}
            />
          )}
        />
        <Controller
          name="phone"
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <TextField
              label="Telefonní číslo"
              InputProps={{
                startAdornment: country && <Box color="text.secondary" paddingRight={0.5}>{`+${country.phone}`}</Box>,
              }}
              {...field}
              error={Boolean(fieldState.error)}
              helperText={fieldState.error && DEFAULT_REQUIRED_MESSAGE}
            />
          )}
        />
      </Stack>
      <Stack spacing={4} flexGrow={1} width={300}>
        <Stack spacing={2}>
          <Controller
            name="paymentMethod"
            control={control}
            rules={{ required: true }}
            defaultValue=""
            render={({ field, fieldState: { error } }) => (
              <FormControl required error={Boolean(error)}>
                <FormLabel>Vyberte typ platby</FormLabel>
                <RadioGroup {...field}>
                  <Stack ml={2} marginTop={2} typography="body2">
                    <FormControlLabel
                      value="online"
                      control={<Radio size="small" sx={{ marginLeft: CHECKBOX_FORM_OFFSET }} />}
                      label={<Box typography="body2">Platební karta</Box>}
                    />
                    <FormControlLabel
                      value="invoice"
                      control={<Radio size="small" sx={{ marginLeft: CHECKBOX_FORM_OFFSET }} />}
                      label={<Box typography="body2">Na fakturu</Box>}
                    />
                  </Stack>
                </RadioGroup>
              </FormControl>
            )}
          />
        </Stack>
        <Stack spacing={2} flexGrow={1}>
          <Controller
            name="reservationConditions"
            control={control}
            rules={{
              required: true,
              validate: validateReservationConditions(["agreeToTermsAndPrivacy"]),
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FormControl required error={Boolean(error)} fullWidth>
                <FormLabel>Podmínky rezervace</FormLabel>
                <FormGroup
                  onChange={({ target }) => {
                    const { name, checked } = target as HTMLInputElement;
                    onChange({ ...value, [name]: checked });
                  }}
                >
                  <Stack spacing={2} marginTop={2}>
                    <FormControlLabel
                      name="agreeToTermsAndPrivacy"
                      checked={value.agreeToTermsAndPrivacy}
                      control={<Checkbox size="small" sx={{ marginLeft: CHECKBOX_FORM_OFFSET }} />}
                      label={
                        <Box typography="body2">
                          Souhlasím s{" "}
                          <Link component={RouterLink} to={links.reservationConditions}>
                            rezervačními podmínkami a se zásadami zpracování osobních údajů
                          </Link>
                        </Box>
                      }
                    />
                    {error && (
                      <Box typography="body2" color="error.main">
                        Pro potvrzení rezervace je nutné souhlasit s rezervačními podmínkami.
                      </Box>
                    )}
                    {/* <FormControlLabel
                      name="agreeToHotelMarketing"
                      checked={value.agreeToHotelMarketing}
                      control={<Checkbox size="small" sx={{ marginLeft: CHECKBOX_FORM_OFFSET }} />}
                      label={
                        <Box typography="body2">
                          Souhlasím se zasíláním{" "}
                          <Link component={RouterLink} to={links.marketingNewsletter}>
                            marketingových sdělení
                          </Link>{" "}
                          hotelu
                        </Box>
                      }
                    /> */}
                  </Stack>
                </FormGroup>
              </FormControl>
            )}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

interface PurchaseFormProps extends Omit<BoxProps<"form">, "onSubmit" | "children" | "defaultValue" | "onChange"> {
  defaultValues?: Partial<PurchaseFormData>;
  onSubmit: (data: PurchaseFormData) => void;
  children: (data: { control: Control<PurchaseFormData> }) => React.ReactNode;
  onChange?: (change: PurchaseFormData) => void;
}
export const PurchaseForm = ({ onSubmit, children, defaultValues, onChange, ...props }: PurchaseFormProps) => {
  const { control, handleSubmit, watch } = useForm<PurchaseFormData>({
    defaultValues: {
      ...DEFAULT_FORM_DATA,
      ...defaultValues,
    },
    shouldUnregister: true,
  });

  const allFields = watch();
  useEffect(() => {
    onChange?.(allFields);
  }, [allFields]);

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} {...props}>
      {children({ control })}
    </Box>
  );
};
