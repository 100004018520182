import { Button, CircularProgress } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { isEmpty, uniqBy } from "lodash";
import { useMemo, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { BookingValidationData, useBookingApi } from "../../api";
import { useAppSettings, useNavigateWhen, useRoomBooking, useUserForm } from "../../hooks";
import { PATHS } from "../../paths";
import { AppSettings, PurchaseFormData, toBookingCreateData, toBookingsDto, toSettingsDto } from "../../types/bookings";
import { Room } from "../../types/room";
import { BackButton } from "../common";
import { FooterContainer } from "./BookingPage";
import { LegislativePanel } from "./LegislativePanel";
import { PageLayout } from "./PageLayout";
import { InvalidCartModal, PurchaseForm, PurchaseFormControls } from "./purchase";
import { SummaryPanel, SummaryPanelStickyContainer } from "./SummaryPanel";

const toBookingValidationData = (
  settings: AppSettings,
  bookings: Room[],
  totalPrice: number,
): BookingValidationData => ({
  prices: { totalPriceTax: totalPrice },
  bookings: toBookingsDto(bookings),
  settings: toSettingsDto(settings),
});

interface PurchasePageProps {
  compact?: boolean;
  summaryPosition?: "top" | "bottom";
}
export const PurchasePage = ({ compact }: PurchasePageProps) => {
  const { bookings, totalPrice, removeRoom } = useRoomBooking();
  const { userDetail, setUserDetail, clear: clearUserForm } = useUserForm();
  const { settings } = useAppSettings();
  const { mutate: createBooking, isPending } = useMutation(useBookingApi().createBooking());
  const navigate = useNavigate();

  const [invalidCartOpen, setInvalidCartOpen] = useState(false);

  const bookingValidationData = toBookingValidationData(settings, bookings, totalPrice);
  const bookingSettings = useMemo(() => settings.booking, [settings]);

  const {
    data: cart,
    isRefetching: isLoading,
    refetch: validateCart,
  } = useQuery(useBookingApi().validateCart(bookingValidationData));

  const dataInCartIsValid = cart?.valid === true;

  useNavigateWhen(isEmpty(bookings), PATHS.HOME);

  const onSubmit = async (data: PurchaseFormData) => {
    await validateCart();

    if (!dataInCartIsValid) {
      setInvalidCartOpen(true);
      return;
    } else {
      createBooking(toBookingCreateData(settings, bookings, data), {
        //NOTE: We PayU prevents us to open their payment gateway in iframe, so we need to open it in new tab
        //There is a possible solution to implement a custom JS event listener running on the client page and then to call (e.g. window.parent.postMessage({ type: 'changeURL', url: 'https://example.com' }, '*'))
        onSuccess: (data) => {
          if (data.paymentInfo.gatewayUrl) {
            clearUserForm();
            window.open(data.paymentInfo.gatewayUrl, "_blank");
          } else {
            navigate({
              pathname: PATHS.INVOICE_CONFIRMATION,
              search: `?${createSearchParams({
                orderId: data.bookingCode,
              })}`,
            });
          }
        },
      });
    }
  };

  return (
    <>
      <PurchaseForm onSubmit={onSubmit} defaultValues={userDetail} onChange={setUserDetail}>
        {({ control }) => (
          <PageLayout
            header={<BackButton path={PATHS.HOME} />}
            compact={compact}
            summaryPosition="bottom"
            summaryPanel={
              compact ? (
                <FooterContainer>
                  <SummaryPanel
                    layout="footer"
                    rooms={bookings}
                    bookingsInfo={bookingSettings}
                    totalPrice={totalPrice}
                    action={
                      <Button
                        type="submit"
                        variant="contained"
                        size="large"
                        color="primary"
                        fullWidth
                        disabled={isLoading || isPending}
                        startIcon={isPending && <CircularProgress sx={{ color: "common.white" }} size={18} />}
                      >
                        Potvrdit rezervaci
                      </Button>
                    }
                  />
                </FooterContainer>
              ) : (
                <SummaryPanelStickyContainer>
                  <SummaryPanel
                    rooms={bookings}
                    bookingsInfo={bookingSettings}
                    totalPrice={totalPrice}
                    action={
                      <Button
                        type="submit"
                        variant="contained"
                        size="large"
                        color="primary"
                        fullWidth
                        disabled={isLoading || isPending}
                        startIcon={isPending && <CircularProgress sx={{ color: "common.white" }} size={18} />}
                      >
                        Potvrdit rezervaci
                      </Button>
                    }
                  />
                </SummaryPanelStickyContainer>
              )
            }
            footer={<LegislativePanel />}
          >
            <PurchaseFormControls control={control} compact={compact} links={settings.common.marketingPages} />
          </PageLayout>
        )}
      </PurchaseForm>
      <InvalidCartModal
        open={invalidCartOpen}
        onClose={() => setInvalidCartOpen(false)}
        onRemove={() => {
          const problemItemIds = uniqBy(cart?.problemItems ?? [], "unitId").map(({ unitId }) => unitId);
          removeRoom(problemItemIds);
          setInvalidCartOpen(false);
        }}
      />
    </>
  );
};
