import { ApiResponse } from "./types";

export interface CustomApiHandlerType<T, R> {
  executed?: (variables: T, data: R) => void;
  failed?: (variables: T, data: R, options: { message: string | undefined; statusCode: number | undefined }) => void;
}

export const onSuccessCustomApiResponse =
  <V, R>({ executed, failed }: CustomApiHandlerType<V, R>) =>
  ({ statusCode, success, results, message }: ApiResponse<R>, variables: V) => {
    return success ? executed?.(variables, results) : failed?.(variables, results, { statusCode, message });
  };
