import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Alert,
  Box,
  Button,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { isEmpty } from "lodash";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { BookingDetailData, useBookingApi } from "../../api";
import { onSuccessCustomApiResponse } from "../../api/utils";
import { useNavigateWhen } from "../../hooks";
import { PATHS } from "../../paths";
import { RouterButton } from "../common";
import { LegislativePanel } from "./LegislativePanel";
import { PageLayout } from "./PageLayout";

interface ReservationResponse {
  success: boolean;
  message?: string;
}

interface ReservationPageProps {
  onCancellation?: () => void;
  reservationResponse?: ReservationResponse | null;
}

const ReservationCancel = ({ reservationResponse, onCancellation }: ReservationPageProps) => (
  <Stack alignItems="center" mt={3}>
    <Stack alignItems="center" gap={2} maxWidth="fit-content">
      <Box sx={{ textAlign: "center" }}>
        <Typography fontWeight="bold">Chcete svou rezervaci zrušit?</Typography>
        <Typography fontSize="14px">
          Rezervace se ihned po zrušení uvolní dalším zájemcům a Váš termín již nemusí být dostupný.
        </Typography>
      </Box>
      <Alert severity="info" sx={{ maxWidth: "600px" }}>
        <Typography fontWeight="bold">Upozornění na zrušení rezervace</Typography>
        <Typography fontSize="14px">
          Pokud zrušíte svou rezervaci více než 14 dní před datem nástupu, vrátíme vám plnou částku. Při zrušení
          rezervace 14 nebo méně dní před nástupem bohužel platí 100% storno poplatek.
        </Typography>
      </Alert>
      {reservationResponse &&
        (reservationResponse.success ? (
          <Alert severity="success" sx={{ maxWidth: "600px" }}>
            Rezervace byl úspěšně zrušena.
          </Alert>
        ) : (
          <Alert severity="error" sx={{ maxWidth: "600px" }}>
            {reservationResponse.message}
          </Alert>
        ))}
      {!reservationResponse && (
        <Button variant="outlined" onClick={onCancellation}>
          Zrušit rezervaci
        </Button>
      )}
    </Stack>
  </Stack>
);

interface ReservationDetailsProps {
  bookingData?: BookingDetailData;
}

const ReservationDetails = ({ bookingData }: ReservationDetailsProps) => {
  const { bookingCode, checkIn, checkOut, totalPrice, contactSurname, contactName } = bookingData ?? {};

  return (
    <Stack alignItems="center" mt={1} p={3}>
      <Box sx={{ width: "600px", maxWidth: "100%", backgroundColor: "grey.A100", borderRadius: 2 }}>
        <TableContainer>
          <Table aria-label="reservation table">
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row" sx={{ fontWeight: "700" }}>
                  Rezervace č.
                </TableCell>
                <TableCell component="th" scope="row">
                  {bookingCode}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row" sx={{ fontWeight: "700" }}>
                  Jméno a příjmení
                </TableCell>
                <TableCell component="th" scope="row">
                  {`${contactName} ${contactSurname}`}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row" sx={{ fontWeight: "700" }}>
                  Datum příjezdu a odjezdu
                </TableCell>
                <TableCell component="th" scope="row">
                  {`${checkIn} - ${checkOut}`}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row" sx={{ fontWeight: "700" }}>
                  Celková cena
                </TableCell>
                <TableCell component="th" scope="row">
                  {totalPrice}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Stack>
  );
};

interface ReservationPageProps {
  compact?: boolean;
}

export const BookingDetailPage = ({ compact }: ReservationPageProps) => {
  const [searchParams] = useSearchParams();
  const bookingCode = searchParams.get("bookingCode");
  const requestToken = searchParams.get("requestToken");

  const [reservationResponse, setReservationResponse] = useState<ReservationResponse | null>(null);

  useNavigateWhen(!requestToken, PATHS.HOME);

  const { data: bookingData, isLoading } = useQuery(
    useBookingApi().getBookingDetail({
      bookingCode: bookingCode ?? "",
      requestToken: requestToken ?? "",
    }),
  );
  const { mutate: cancelReservation } = useMutation(useBookingApi().cancelBooking());

  const handleCancelBooking = () => {
    cancelReservation(
      {
        bookingCode: bookingCode ?? "",
        requestToken: requestToken ?? "",
      },
      {
        onSuccess: onSuccessCustomApiResponse({
          executed: () => setReservationResponse({ success: true }),
          failed: (_, __, data) => {
            let errorMessage;
            switch (data?.statusCode) {
              case 400:
                errorMessage = "Rezervace nenalezena";
                break;
              case 404:
                errorMessage = "Rezervace již byla zrušena";
                break;
              default:
                errorMessage = "Něco se pokazilo, zkuste to prosím znovu.";
            }
            return setReservationResponse({ success: false, message: errorMessage });
          },
        }),
        onError: (error) => console.error(error),
      },
    );
  };

  return (
    <PageLayout summaryPosition="top-bottom" loading={isLoading} compact={compact} footer={<LegislativePanel />}>
      <Stack justifyContent="center" gap={1}>
        <Typography variant="h1" typography="h3" textAlign="center">
          Stav rezervace
        </Typography>
        {!isEmpty(bookingData) ? (
          <Typography textAlign="center">
            Níže naleznete detail Vaši rezervace{" "}
            <Typography component="span" fontWeight="bold">
              č. {bookingData?.bookingCode}
            </Typography>
          </Typography>
        ) : (
          <Stack alignItems="center">
            <Stack gap={2} sx={{ maxWidth: "600px" }}>
              <Alert severity="error">Rezervace nenalezena!</Alert>
              <RouterButton startIcon={<ArrowBackIcon />} to={PATHS.HOME} variant="contained" color="primary">
                Zpět na výběr pokojů
              </RouterButton>
            </Stack>
          </Stack>
        )}
      </Stack>
      {!isEmpty(bookingData) && (
        <>
          <ReservationDetails bookingData={bookingData} />
          <Divider />
          <ReservationCancel onCancellation={handleCancelBooking} reservationResponse={reservationResponse} />
        </>
      )}
    </PageLayout>
  );
};
