import { Box, BoxProps, Paper, Stack } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Datepicker from "react-tailwindcss-datepicker";
import { useBookingApi } from "../../api";
import { MIN_DATE } from "../../constants";
import { useAppSettings, useRoomBooking } from "../../hooks";
import { PATHS } from "../../paths";
import { BookingSettings } from "../../types/bookings";
import { Room } from "../../types/room";
import { BookingSummaryPanel, RoomList } from "./booking";
import { disabledDateRange, RoomDetailAvailabilityModal } from "./booking/RoomDetailAvailabilityModal";
import { RoomDetailModal } from "./booking/RoomDetailModal";
import { LegislativePanel } from "./LegislativePanel";
import { PageLayout } from "./PageLayout";
import { SummaryPanelStickyContainer } from "./SummaryPanel";

interface FooterProps extends BoxProps {}

export const FooterContainer = ({ sx = {}, ...props }: FooterProps) => (
  <Box
    sx={{
      position: "relative",
      bottom: 0,
      left: 0,
      right: 0,
      bgcolor: "common.white",
      border: ({ palette }) => `1px solid ${palette.divider}`,
      borderRadius: 2,
      boxShadow: 0,
      paddingX: 3,
      paddingY: 2,
      zIndex: -10,
      ...sx,
    }}
    {...props}
  />
);

interface BookingSettingsPanelProps {
  settings: BookingSettings;
  compact?: boolean;
  onChange?: (settings: Partial<BookingSettings>) => void;
}

const BookingSettingsPanel = ({
  settings: { dateFrom, dateTo, maxDate },
  compact,
  onChange,
}: BookingSettingsPanelProps) => (
  <Stack direction={compact ? "column" : "row"} spacing={4} zIndex={11} position={"relative"}>
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      borderRadius={2}
      padding={2}
      width="100%"
      sx={{
        border: ({ palette }) => `1px solid ${palette.divider}`,
        backgroundColor: ({ palette }) => `${palette.background.default}`,
      }}
    >
      <Box fontWeight="bold">Období ubytování:</Box>
      <Paper sx={compact ? { width: "100%" } : { width: 280 }}>
        <Datepicker
          i18n="cs-CZ"
          value={{
            startDate: dateFrom,
            endDate: dateTo,
          }}
          primaryColor="amber"
          startWeekOn="mon"
          separator=" - "
          onChange={(value) => {
            if (value !== null && value.startDate !== null && value.endDate !== null) {
              const { startDate, endDate } = value;
              onChange?.({ dateFrom: new Date(startDate), dateTo: new Date(endDate) });
            }
          }}
          minDate={MIN_DATE}
          maxDate={maxDate}
          readOnly
          displayFormat="DD.MM.YYYY"
        />
      </Paper>
    </Stack>
    {/* <Stack direction="row" spacing={2} alignItems="center">
      <Box fontWeight="bold">Počet osob:</Box>
      <NumberStepCount count={occupancy} min={1} max={4} onChange={(value) => onChange?.({ occupancy: value })} />
    </Stack> */}
  </Stack>
);

interface BookingPageProps {
  compact?: boolean;
}

export const BookingPage = ({ compact }: BookingPageProps) => {
  const {
    settings: { booking: bookingSettings, common: commonSettings },
    update: updateSettings,
  } = useAppSettings();
  const { data: rooms, isLoading } = useQuery(
    useBookingApi().getRooms(commonSettings.hotelId, bookingSettings.dateFrom, bookingSettings.dateTo),
  );
  const { mutate: getRoomAvailability } = useMutation(useBookingApi().checkRoomAvailability());

  const sortedRooms = useMemo(() => rooms?.sort((a, b) => Number(b.available) - Number(a.available)) || [], [rooms]);

  const { bookings, addRoom, removeRoom, clear, totalPrice } = useRoomBooking();
  const [modal, setModalOpen] = useState<Room | undefined>(undefined);
  const [disabledDates, setDisabledDates] = useState<disabledDateRange[] | undefined>(undefined);
  const navigate = useNavigate();

  const handleReservationConfirm = () => {
    navigate(PATHS.PURCHASE);
  };

  return (
    <PageLayout
      summaryPosition="top-bottom"
      loading={isLoading}
      header={
        <BookingSettingsPanel
          settings={bookingSettings}
          compact={compact}
          onChange={(settings) => {
            updateSettings(settings);
            //When settings change, we remove all of the previous bookings, because we cant be sure that they are still valid
            //Probably we should run the validation check here and inform user about the changes
            clear();
          }}
        />
      }
      compact={compact}
      summaryPanel={
        compact ? (
          <FooterContainer>
            <BookingSummaryPanel
              layout="footer"
              bookingsInfo={bookingSettings}
              rooms={bookings}
              totalPrice={totalPrice}
              onRemoveAll={clear}
              onReservationConfirm={handleReservationConfirm}
            />
          </FooterContainer>
        ) : (
          <SummaryPanelStickyContainer>
            <BookingSummaryPanel
              rooms={bookings}
              bookingsInfo={bookingSettings}
              totalPrice={totalPrice}
              onRemove={(roomId) => removeRoom([roomId])}
              onReservationConfirm={handleReservationConfirm}
            />
          </SummaryPanelStickyContainer>
        )
      }
      footer={<LegislativePanel />}
    >
      <RoomList
        rooms={sortedRooms}
        slotProps={{
          item: {
            onImageClick: (room) => setModalOpen(room),
            onReserve: (_, room) => addRoom(room),
            onAvailabilityCheck: (roomId) =>
              getRoomAvailability(
                {
                  hotelId: commonSettings.hotelId,
                  roomId,
                  startDate: new Date(dayjs().format("YYYY-MM-DD")),
                },
                {
                  onSuccess: (data) => {
                    setDisabledDates(data);
                  },
                },
              ),
            compact,
          },
        }}
      />
      {modal && (
        <RoomDetailModal
          room={modal}
          open={Boolean(modal)}
          onClose={() => setModalOpen(undefined)}
          onReserve={(room) => {
            addRoom(room);
            setModalOpen(undefined);
          }}
          compact={compact}
        />
      )}
      {disabledDates && (
        <RoomDetailAvailabilityModal
          disabledDates={disabledDates}
          open={Boolean(disabledDates)}
          onClose={() => setDisabledDates(undefined)}
          compact={compact}
        />
      )}
    </PageLayout>
  );
};
//
