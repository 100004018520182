import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Stack, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useNavigateWhen } from "../../hooks";
import { PATHS } from "../../paths";
import { BackButton, RouterButton } from "../common";
import { LegislativePanel } from "./LegislativePanel";
import { PageLayout } from "./PageLayout";

interface InvoiceConfirmationPageProps {
  compact?: boolean;
}

export const InvoiceConfirmationPage = ({ compact }: InvoiceConfirmationPageProps) => {
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get("orderId");

  useNavigateWhen(!orderId, PATHS.HOME);

  return (
    <PageLayout header={<BackButton />} compact={compact} footer={<LegislativePanel />}>
      <Stack gap={2} justifyContent="center" alignItems="center">
        <CheckCircleIcon sx={{ fontSize: 140 }} color="success" />
        <Typography variant="h1" typography="h3">
          {`Rezervace č. ${orderId} úspěšná!`}
        </Typography>
        <Typography>Více informací jsme vám zaslali na e-mail, který jste uvedli u rezervace.</Typography>
        <RouterButton startIcon={<ArrowBackIcon />} to={PATHS.HOME} variant="contained" color="primary">
          Zpět na výběr pokojů
        </RouterButton>
      </Stack>
    </PageLayout>
  );
};
